





















































































import { FormService } from '@/includes/services/FormService'
import FullForm from '@/components/Forms/FullForm.vue'
import { prepareFormForAnswer, prepareFrontendFields } from '@/components/Forms/logic/FormsHelper'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { AnsweredUser, Form } from '@/includes/types/Form.types'
import FormSuccessMessageImageList from '@/components/Forms/components/FormSuccessMessageImageList.vue'

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import ZoomableImage from 'piramis-base-components/src/components/ZoomableImage/ZoomableImage.vue'
import isTelegramWebview from "piramis-base-components/src/shared/utils/isTelegramWebview";

import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    FormSuccessMessageImageList,
    FullForm,
    CenteredColumnLayout,
    UserMainInfo,
    ZoomableImage
  },
  methods: {
    isTelegramWebview,
  }
})
export default class FormPublic extends Vue {
  form: Required<Form> | null = null

  frontendFields:any = null

  formCreated = false

  user: AnsweredUser | null = null

  boardKey: string | null = null

  botUrl: string | null = null

  error = false

  get targetToUpload():any {
    return () => {
      return {
        target: 'target',
        target_id: this.boardKey
      }
    }
  }

  async createAnswer():Promise<void> {
    try {
      const answer = prepareFormForAnswer(this.frontendFields)

      const { url } = await FormService.createFormAnswer('tg', {
        token: this.$route.params.formToken.toString(),
        answer: {
          values: answer
        },
      })

      successNotification()

      this.formCreated = true

      if (!this.form?.config.success_message) {
        setTimeout(() => {
          window.location.href = url
        }, 1500);
      } else {
        this.botUrl = url
      }

    } catch (e:any) {
      errorNotification(e)
    }
  }

  created():void {
    FormService.getQuestionnaire('tg', {
      token: this.$route.params.formToken,
    })
      .then(({ form, user, board_key }) => {
        this.form = form
        this.user = user
        this.boardKey = board_key

        this.frontendFields = prepareFrontendFields(this.form.config.fields)
      })
      .catch((error) => {
        errorNotification(error)

        this.error = true
      })
  }
}
